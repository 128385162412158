import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    jwt_token: null,
    user: null,
    person: {},
    meetSettings: {
      hasAudio: false,
      hasVideo: false,
      camStatus: false,
      micStatus: false,
      screenStatus: false
    },
    roomState: null
  },
  getters: {
    getJWT: (state) => {
      return state.jwt_token
    },
    getLocalUser: (state) => {
      return state.user
    },
    camStatus (state) {
      return state.meetSettings.camStatus
    },
    micStatus (state) {
      return state.meetSettings.micStatus
    },
    screenStatus (state) {
      return state.meetSettings.screenStatus
    }
  },
  mutations: {
    setJWT (state, token) {
      state.jwt_token = token
    },
    setLocalUser (state, user) {
      state.user = user
    },
    setPerson (state, { person }) {
      state.person = person
    },
    CAM_STATUS_CHANGE (state, payload) {
      state.meetSettings.camStatus = payload
    },
    MIC_STATUS_CHANGE (state, payload) {
      state.meetSettings.micStatus = payload
    },
    SCREEN_STATUS_CHANGE (state, payload) {
      state.meetSettings.screenStatus = payload
    }
  },
  actions: {
    setPerson (context, person) {
      context.commit('setPerson', { person })
    },
    toggleCamStatus (ctx, params) {
      ctx.commit('CAM_STATUS_CHANGE', params)
    },
    toggleMicStatus (ctx, params) {
      ctx.commit('MIC_STATUS_CHANGE', params)
    },
    toggleScreenStatus (ctx, params) {
      ctx.commit('SCREEN_STATUS_CHANGE', params)
    }
  }
})
