import appUtils from '../../utils/appUtils'

export const useConnectionOptions = {
  data () {
    const initialSettings = {
      trackSwitchOffMode: undefined,
      dominantSpeakerPriority: 'high',
      bandwidthProfileMode: 'collaboration',
      maxAudioBitrate: '16000',
      contentPreferencesMode: 'auto',
      clientTrackSwitchOffControl: 'auto'
    }

    return {
      initialSettings,
      connectionOptionsRaw: {
        bandwidthProfile: {
          video: {
            mode: initialSettings.bandwidthProfileMode,
            dominantSpeakerPriority: initialSettings.dominantSpeakerPriority,
            trackSwitchOffMode: initialSettings.trackSwitchOffMode,
            contentPreferencesMode: initialSettings.contentPreferencesMode,
            clientTrackSwitchOffControl:
              initialSettings.clientTrackSwitchOffControl
          }
        },
        dominantSpeaker: true,
        networkQuality: { local: 3, remote: 3 },
        maxAudioBitrate: Number(initialSettings.maxAudioBitrate),
        preferredVideoCodecs: 'auto',
        preferredAudioCodecs: [{ codec: 'opus', dtx: false }]
      },
      connectionOptions: null
    }
  },
  mounted () {
    if (
      appUtils.isMobile &&
      this.connectionOptionsRaw?.bandwidthProfile?.video
    ) {
      this.connectionOptionsRaw.bandwidthProfile.video.maxSubscriptionBitrate = 2500000
    }

    this.connectionOptions = appUtils.removeUndefineds(
      this.connectionOptionsRaw
    )
  }
}
