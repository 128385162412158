import appUtils from '../../utils/appUtils'

export const useDevices = {
  data () {
    return {
      deviceInfo: {
        audioInputDevices: [],
        videoInputDevices: [],
        audioOutputDevices: [],
        hasAudioInputDevices: false,
        hasVideoInputDevices: false
      }
    }
  },
  created () {
    const getDevices = () =>
      appUtils.getDeviceInfo().then((devices) => this.setDeviceInfo(devices))
    navigator.mediaDevices.addEventListener('devicechange', getDevices)
    getDevices()
  },
  methods: {
    setDeviceInfo (value) {
      this.deviceInfo = value
    }
  },
  destroyed () {
    const getDevices = () =>
      appUtils.getDeviceInfo().then((devices) => this.setDeviceInfo(devices))
    navigator.mediaDevices.removeEventListener('devicechange', getDevices)
  }
}
