<template>
  <div
    :class="false ? '' : 'position-absolute top-0 start-0 w-100 h-0 opacity-0 overflow-hidden pe-none'"
    style="z-index: -1"
  >
    <div class="bg-white text-black conntainer mx-auto" ref="documente">
      <div id="TTTQMTemp" class="pl-3 pr-3 fs-14">
        <div>
          <div class="bs-callout bs-callout row mb-2">
            <div class="col-3 uppercase">
              <div class="inline-block" style="width: 300px">
                <p
                  class="mb-0 fs-12 font-bold"
                >{{ clinic && clinic.organization ? clinic.organization.authority_in_charge : "&nbsp;" }}</p>
                <p
                  class="mb-0 fs-12 font-bold"
                >{{ clinic && clinic.organization ? clinic.organization.name : "&nbsp;" }}</p>
                <p class="mb-0 fs-10 font-bold">{{ form.dept }}</p>
              </div>
            </div>
            <div class="col-6 text-center">
              <p class="robo-12-700 mb-0">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
              <p class="robo-12-700 mb-0">Độc lập - Tự do - Hạnh phúc</p>
              <p class="robo-12-700 mb-0">------------------------------</p>
            </div>
            <div class="col-3 d-flex justify-content-end text-right">
              <div class="whitespace-nowrap inline-block text-left">
                <div class="robo-12-400">MS: {{ '42/BV-01' || '................................' }}</div>
                <!-- <div
                  class="robo-12-400"
                >Mã BN: {{ mdt_disease && mdt_disease.medical_record && mdt_disease.medical_record.person_ref_id || '..........................' }}</div>-->
                <div class="robo-12-400">Mã BN: {{ '' || '..........................' }}</div>
                <div
                  class="robo-12-400"
                >Số vào viện: {{ form.code || (mdt_disease && mdt_disease.medical_record && mdt_disease.medical_record.ref_id) || '...................' }}</div>
              </div>
            </div>
          </div>
          <div class="bs-callout bs-callout mb-2">
            <p class="robo-18-700 text-center font-weight-bold">TÓM TẮT THÔNG QUA MỔ</p>
          </div>
          <div class="bs-callout bs-callout mb-1">
            <div class="row">
              <div class="col-6">
                <p class="robo-12-400 mb-0 lh-sm">
                  Họ tên người bệnh:
                  <span class="fw-bold text-uppercase">
                    {{
                    form.name
                    }}
                  </span>
                </p>
                <p class="robo-12-400 mb-0 lh-sm">Địa chỉ: {{ form.address }}</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <p class="robo-12-400 mb-0 lh-sm">
                      Tuổi:
                      <span class="fw-bold">
                        {{
                        mdt_disease &&
                        mdt_disease.patient_birthday &&
                        mdtUtils.getAgesWithOutLang(
                        new Date(mdt_disease.patient_birthday)
                        )
                        }}
                      </span>
                    </p>
                  </div>
                  <div class="col-6">
                    <p class="robo-12-400 mb-0 lh-sm">
                      Nam/Nữ:
                      <span class="fw-bold">
                        {{
                        mdtUtils.getGenderWithOutLang(form.sex)
                        }}
                      </span>
                    </p>
                  </div>
                  <div class="col-12">
                    <p class="robo-12-400 mb-0 lh-sm">Khoa: {{ form.dept }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 robo-12-400 mb-0 lh-sm">
                <span class="me-1">Buồng:</span>
                <span>{{ form && form.admission_room }}</span>
              </div>
              <div class="col-6 robo-12-400 mb-0 lh-sm">
                <span class="me-1">Giường:</span>
                <span>{{ form && form.admission_bed }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-4 robo-12-400 mb-0 lh-sm whitespace-nowrap">
                <span>Đã điều trị từ:</span>
                <span>{{ form && form.treatment_time_start && getDateTime(form.treatment_time_start) }}</span>
              </div>
              <div class="col-4 robo-12-400 mb-0 lh-sm whitespace-nowrap">
                <span>Đến ngày:</span>
                <span>{{ form && form.treatment_time_end && getDateTime(form.treatment_time_end) }}</span>
              </div>
              <div class="col-4 robo-12-400 mb-0 lh-sm whitespace-nowrap">
                <span class="mr-1">Hội chẩn lúc:</span>
                <span>{{ form && form.mdt_time && getDateTime(form.mdt_time) }}</span>
              </div>
            </div>
            <div class="mb-1 robo-14-400">
              <div class="overflow-hidden">
                <div class="float-start me-1 mb-0">Chẩn đoán:</div>
                <div class="robo-14-400" v-html="form && form.diagnostic"></div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-6 robo-12-400 mb-0 lh-sm">
                <span class="me-1">{{ $t('mdt_room.lbl_mems_preside') }}:</span>
                <span>{{ form && form.preside }}</span>
              </div>
              <div class="col-6 robo-12-400 mb-0 lh-sm">
                <span class="me-1">{{ $t('mdt_room.lbl_mems_type_secretary') }}:</span>
                <span>{{ form && form.secretary }}</span>
              </div>
            </div>
            <div class="mb-1 robo-14-400">
              <div class="overflow-hidden">
                <div class="float-start me-1 mb-0">{{ $t('mdt_room.lbl_participant') }}:</div>
                <div
                  class="robo-14-400"
                  v-html="form && form.members && form.members.substring(0, form.members.length - 1)"
                ></div>
              </div>
            </div>-->
          </div>
          <div class="bs-callout bs-callout mb-2">
            <div class="mb-2">
              <div
                class="robo-13-700 mb-1 text-uppercase"
              >I. Tóm tắt quá trình diễn biến bệnh, quá trình điều trị và chăm sóc người bệnh</div>
              <div class="robo-14-400 box-editor" v-html="form.medical_history"></div>
            </div>
            <div>
              <div class="robo-13-700 mb-1">
                <span class="text-uppercase me-1">Kết luận:</span>
                <span>(Sau khi đã khám lại và thảo luận)</span>
              </div>
              <div class="robo-14-400 box-editor" v-html="form.conclusion"></div>
            </div>
          </div>
          <div class="bs-callout bs-callout">
            <div class="robo-13-700 mb-2 text-uppercase">II. Xét nghiệm</div>
          </div>
          <div class="bs-callout bs-callout row mb-2">
            <div class="col-12 robo-12-400 mb-0 lh-sm">1. Xét nghiệm máu</div>
            <div class="col-3">
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>HST: {{ form.test_hst }}</span>
                <span>g/l</span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>HC: {{ form.test_hc }}</span>
                <span>T/l</span>
              </div>
              <!-- <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>Hematocrit: {{ form.test_hematocrit }}</span>
                <span>l/l</span>
              </div>-->
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>HCT: {{ form.test_hct }}</span>
                <span>%</span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>TC: {{ form.test_tc }}</span>
                <span>G/l</span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>Đông máu CB: {{ form.test_blood_basic }}</span>
              </div>
            </div>
            <div class="col-3">
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>BC: {{ form.test_bc }}</span>
                <span>G/l</span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>&nbsp;</span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>&nbsp;</span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>Nhóm Máu: {{ form.test_blood }}</span>
                <span></span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>PT% {{ form.test_pt }}</span>
                <span></span>
              </div>
            </div>
            <div class="col-3">
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>Ure: {{ form.test_ure }}</span>
                <span>mmol/l</span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>Creatinin: {{ form.test_creatinin }}</span>
                <span>umol/l</span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>Glucose: {{ form.test_glucose }}</span>
                <span>mmol/l</span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>Protit: {{ form.test_protit }}</span>
                <span>g/l</span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>Cholesterol: {{ form.test_cholesterol }}</span>
                <span>mmol/l</span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>Quick: {{ form.test_quick }}</span>
              </div>
            </div>
            <div class="col-3">
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>Bilirubin TP: {{ form.test_bilirubin_tp }}</span>
                <span>mmol/l</span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>Bilirubin TT: {{ form.test_bilirubin_tt }}</span>
                <span>mmol/l</span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>GOT: {{ form.test_got }}</span>
                <span>U/l</span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>GPT: {{ form.test_gpt }}</span>
                <span>U/l</span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>&nbsp;</span>
              </div>
              <div class="robo-12-400 mb-0 lh-sm d-flex justify-content-between">
                <span>GGT: {{ form.test_ggt }}</span>
              </div>
            </div>
            <div class="robo-12-400 mb-0 lh-sm">2. Nước tiểu: {{ form.urine }}</div>
            <div class="robo-12-400 mb-0 lh-sm">3. Xquang: {{ form.xray }}</div>
            <div class="robo-12-400 mb-0 lh-sm">4. Điện tim: {{ form.electrocar }}</div>
            <div class="robo-12-400 mb-0 lh-sm">5. Siêu âm: {{ form.ultrasound }}</div>
            <div class="robo-14-400">6. Nội soi: {{ form.endoscopy }}</div>
          </div>
          <div class="bs-callout bs-callout">
            <div
              class="mb-2 robo-13-700 text-uppercase"
            >III. Chuẩn bị người bệnh và phương pháp điều trị</div>
          </div>
          <div class="bs-callout bs-callout mb-2">
            <p class="robo-12-400 mb-0 lh-sm">1. Thử phản ứng thuốc:{{ form.drug_reaction }}</p>
            <p class="robo-12-400 mb-0 lh-sm">2. Kháng sinh trước mổ: {{ form.antibiotics }}</p>
            <p class="robo-12-400 mb-0 lh-sm">3. Phương pháp phẫu thuật: {{ form.surgery_method }}</p>
            <p class="robo-12-400 mb-0 lh-sm">4. Phương pháp vô cảm: {{ form.anaesthetic_method }}</p>
            <p class="robo-12-400 mb-0 lh-sm">5. Kíp mổ: {{ form.surgical_team }}</p>
            <p class="robo-12-400 mb-0 lh-sm">6. Kíp gây mê: {{ form.anaesthetic_team }}</p>
            <p class="robo-12-400 mb-0 lh-sm">7. Giải phẫu bệnh: {{ form.anatomic }}</p>
            <p class="robo-12-400 mb-0 lh-sm">
              8. Dự trù máu: {{ form.blood_plan }}
              <span
                class="l-text"
              >Dự kiến ngày mổ: {{ formatDMY(form.surgery_date) }}</span>
            </p>
            <p class="robo-12-400 mb-0 lh-sm">9. Dự kiến khó khăn tiên lượng: {{ form.prognostic }}</p>
          </div>
          <div class="bs-callout bs-callout row text-center">
            <div class="col-4 text-center">
              <p class="robo-12-700 mb-4 pb-3 text-uppercase">Trưởng khoa PT và gmhs</p>
              <p class="robo-14-400">{{ form.gm_sur_anes_name }}</p>
            </div>
            <div class="col-4 text-center">
              <p class="robo-12-700 mb-4 pb-3 text-uppercase">Trưởng khoa ngoại</p>
              <p class="robo-14-400">{{ form.gm_surgery_name }}</p>
            </div>
            <div class="col-4 text-center">
              <p class="robo-12-700 mb-4 pb-3 text-uppercase">Bác sĩ điều trị</p>
              <p class="robo-14-400">{{ form.doctor_name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import mdtUtils from '../../utils/mdtUtils'
import appUtils from '../../utils/appUtils'
import uploadImagePrint from '../../utils/uploadImagePrint'

export default {
  name: 'TTTQMTemplate',
  props: ['form', 'save_pdf', 'room_info', 'show', 'mdt_disease'],
  data () {
    return {
      mdtUtils,
      appUtils,
      clinic: this.room_info?.clinic || null
    }
  },
  mounted () {
    this.clinic = this.room_info?.clinic
  },
  watch: {
    save_pdf (count) {
      this.exportPdf()
    },
    room_info (data) {
      if (!data) return
      this.clinic = data.clinic
    }
  },
  methods: {
    async captureImageCanvas (doc) {
      const options = {
        dpi: 2000,
        scale: 10,
        backgroundColor: '#ffffff',
        image: { type: 'jpeg', quality: 1 },
        letterRendering: true,
        allowTaint: true,
        logging: true,
        useCORS: true
      }
      const dataCanvas = await html2canvas(doc, options)
        .then(canvas => {
          const imgBase64Data = canvas.toDataURL('image/jpeg')
          return imgBase64Data
        })
        .catch(() => {})

      return dataCanvas
    },
    async exportPdf () {
      const self = this
      const refDocument = [self.$refs.documente]
      await Promise.all(
        refDocument?.map(async doc => {
          const imgBase64Data = await this.captureImageCanvas(doc)
          const res = await uploadImagePrint.fileUploadHandler(imgBase64Data)
          const wrap = document.createElement('div')
          const div = document.createElement('div')
          const img = document.createElement('img')

          img.src = res
          img.style.maxWidth = '100%'
          div.appendChild(img)
          wrap.appendChild(div)

          return wrap.innerHTML
        })
      ).then(async res => {
        let htmlRaw = ''

        console.log(res)

        // eslint-disable-next-line no-unused-expressions
        res?.map((img, index) => {
          if (index < res.length - 1) {
            htmlRaw += `${img}<div class="html2pdf__page-break"></div>`
          } else {
            htmlRaw += img
          }
        })

        const tempHTML = appUtils.templateHTMLPrint({
          bodyHTML: `<div class="hodo-print">${htmlRaw}</div>`
        })
        const base64Data = appUtils.base64.decode(tempHTML)

        console.log(tempHTML, base64Data)

        this.$emit('onSubmiting', base64Data)
      })
    },
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },
    getDateTime (d) {
      let string = this.moment(d).format('HH:mm-DD/MM/YYYY')
      string = string.replace('-', ' ngày ')
      string = string.replace('00:00', '--:--')
      return string
    },
    formatDMY (d) {
      return this.moment(d).format('DD/MM/YYYY')
    },
    getGender (data) {
      return mdtUtils.getGender(data)
    }
  }
}
</script>
<style scoped>
/* vietnamese */
@font-face {
  font-family: "Times";
  font-display: swap;
  src: url(https://hodo.app/assets/font/tnr.ttf) format("ttf");
}
html,
body {
  font-family: "Times New Roman", "Times", "Tinos", serif !important;
  font-size: 1.0625rem;
  line-height: 1.5rem;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0em;
  color: #000000 !important;
}
img {
  max-width: 100%;
}
img,
svg {
  vertical-align: middle;
}
.content-none {
  width: 100%;
  border-bottom: 2px dotted;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.font {
  font-family: "Times New Roman", Tinos, Times, serif !important;
}
.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}
p {
  margin: 0;
}
.text-italic {
  font-style: italic;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}
.left-0 {
  left: 0;
}
.px-8 {
  padding-right: 2rem;
  padding-left: 2rem;
}
.overflow-hidden {
  overflow: hidden;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.float-none {
  float: none;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block !important;
}
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.align-top {
  vertical-align: top;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.normal-case {
  text-transform: none;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.leading-none {
  line-height: 1;
}
.align-middle {
  vertical-align: middle;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: end;
}
.justify-between {
  justify-content: space-between;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.col-4 {
  width: 33.33% !important;
  display: inline-block !important;
  vertical-align: top;
}
.col-3 {
  width: 25% !important;
  display: inline-block !important;
  vertical-align: top;
}
.col-4 {
  width: 33.3333333% !important;
  display: inline-block !important;
  vertical-align: top;
}
.col-5 {
  width: 41% !important;
  display: inline-block !important;
  vertical-align: top;
}
.col-6 {
  width: 50% !important;
  display: inline-block !important;
  vertical-align: top;
}
.col-7 {
  width: 58.3333333% !important;
  display: inline-block !important;
  vertical-align: top;
}
.col-12 {
  width: 100% !important;
  display: inline-block !important;
  vertical-align: top;
}
.w-full {
  width: 100% !important;
}
.w-half {
  width: 50% !important;
}
.w-one-third {
  width: 33.333% !important;
}
.w-two-thirds {
  width: 66.667% !important;
}
.font-normal {
  font-weight: 400;
}
.font-bold {
  font-weight: 700;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.fs-14 {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.content-size.fs-14 * {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}
.text-sm {
  font-size: 1rem;
  line-height: 1.25rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.fs-16 {
  font-size: 1rem;
  line-height: 1.5rem;
}
.content-size.fs-16 * {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.pt-12 {
  padding-top: 3rem;
}
.p-0 {
  padding: 0;
}
.p-1 {
  padding: 0.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pl-12 {
  padding-left: 3rem;
}
.pl-16 {
  padding-left: 4rem;
}
.pr-12 {
  padding-right: 3rem;
}
.pr-16 {
  padding-right: 4rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.m-6 {
  margin: 1.5rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mt-16 {
  margin-top: 4rem;
}
.mb-16 {
  margin-bottom: 4rem;
}
.mt-20 {
  margin-top: 5rem;
}
.mt-24 {
  margin-top: 6rem;
}
.mt-44 {
  margin-top: 11rem;
}
.ml-auto {
  margin-left: auto;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-5 {
  margin-left: 1.25rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.hodo-table figure {
  margin: 0 0 1rem !important;
}
.hodo-table table {
  border-collapse: collapse !important;
  width: 100% !important;
  border: 1px solid #dbdde0 !important;
  margin-top: 1rem !important;
}
.hodo-table.border-black table {
  border-color: black !important;
}
.hodo-table table thead th {
  text-transform: uppercase !important;
}
.hodo-table table td,
.hodo-table table th {
  padding: 8px !important;
  border: 1px solid #dbdde0 !important;
}
.hodo-table.border-black table td,
.hodo-table.border-black table th {
  border-color: black !important;
}
.box-number {
  border: 1px solid;
  height: 1.5rem !important;
}
.box-item {
  font-family: "Times New Roman", Tinos, Times, serif !important;
  height: 1.5rem !important;
  width: 1.5rem !important;
  border-right: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-item-bhyt {
  font-family: "Times New Roman", Tinos, Times, serif !important;
  height: 0.5rem !important;
  padding: 0.5rem;
  border-right: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-item-bhyt:last-child,
.box-item:last-child {
  border-right: none;
}
.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}
.table-width-full table {
  width: 100% !important;
}
.table-width-full {
  padding-right: 3rem;
}
.el-table--border,
.el-table--group {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-left: 1px solid #000;
}
.el-table th.el-table__cell.is-leaf {
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}
.el-table__row:last-child,
.el-table__cell:last-child,
.el-table th.el-table__cell.is-leaf:last-child {
  border-right: none;
}
.el-table__cell {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}
.el-table--border th.el-table__cell > div.cell {
  line-height: 1.25 !important;
}
thead th.padding-header {
  padding: 0 8px !important;
}
thead th.header-text-left {
  text-align: left !important;
  padding: 0 8px !important;
}
.break-words {
  overflow-wrap: break-word !important;
  word-break: break-word !important;
  word-wrap: break-word !important;
  white-space: break-spaces !important;
}
.border-box {
  border: 1px solid #000;
}
.html2pdf__page-break {
  page-break-before: always;
}
.h-40 {
  height: 10rem;
}
.border {
  border: 1px solid #e0e0e0 !important;
}
.border-t {
  border-top: 1px solid #000;
}
.border-b {
  border-bottom: 1px solid #000;
}
.border-l {
  border-left: 1px solid #000;
}
.border-r {
  border-right: 1px solid #000;
}
.text-black,
.text-black * {
  color: #000000 !important;
}
.conntainer {
  width: 715px;
  margin: 0;
  padding: 0;
}
.l-text {
  margin-left: 230px;
}
.robo-10-700 {
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}
.robo-11-700 {
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}
.robo-12-700 {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}
.robo-12-400 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}
.robo-13-700 {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}
.robo-14-700 {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}
.robo-18-700 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}
.robo-16-700 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}
.robo-13-400 {
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0em;
}
.robo-10-400 {
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0em;
}
.robo-10-400 {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}
.textarea {
  white-space: pre-line;
}
.fs-11 {
  font-size: 11px;
}

.box-editor /deep/ p {
  margin-bottom: 0;
}
</style>
