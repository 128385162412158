export const useDominantSpeaker = (includeNull) => ({
  inject: ['videoProvide'],
  data () {
    return {
      room: this.videoProvide.room || null,
      dominantSpeaker: this.videoProvide.room?.dominantSpeaker || null,
      includeDominantSpeakerNull: false
    }
  },
  watch: {
    includeNull: {
      handler (status) {
        this.includeDominantSpeakerNull = status
      },
      deep: true,
      immediate: true
    },
    videoProvide: {
      handler (data) {
        this.room = data.room || null
      },
      deep: true,
      immediate: true
    },
    room: {
      handler (data) {
        this.dominantSpeaker = data?.dominantSpeaker || null
        this.setDominantSpeakerChangedOnRoom(data)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleDominantSpeakerChanged (newDominantSpeaker) {
      if (this.includeDominantSpeakerNull || newDominantSpeaker !== null) {
        this.dominantSpeaker = newDominantSpeaker
      }
    },
    handleParticipantDisconnected (participant) {
      if (this.dominantSpeaker === participant) {
        this.dominantSpeaker = null
      }
    },
    setDominantSpeakerChangedOnRoom (data) {
      if (!this.room) return
      const roomData = data || this.room
      roomData.on('dominantSpeakerChanged', this.handleDominantSpeakerChanged)
      roomData.on(
        'participantDisconnected',
        this.handleParticipantDisconnected
      )
    },
    setDominantSpeakerChangedOffRoom (data) {
      if (!this.room) return
      const roomData = data || this.room
      roomData.off('dominantSpeakerChanged', this.handleDominantSpeakerChanged)
      roomData.off(
        'participantDisconnected',
        this.handleParticipantDisconnected
      )
    }
  },
  destroyed () {
    this.setDominantSpeakerChangedOffRoom()
  }
})
