export const useTracks = (participant) => ({
  data () {
    return {
      participantData: null,
      tracks: null
    }
  },
  watch: {
    participant: {
      handler (data) {
        this.participantData = data
      },
      deep: true,
      immediate: true
    },
    participantData: {
      handler (data) {
        const subscribedTracks = Array.from(data?.tracks?.values())
          .filter((trackPublication) => trackPublication?.track !== null)
          .map((trackPublication) => trackPublication?.track)

        this.setTracks(subscribedTracks)
        this.setTrackSubscribedOnParticipant(data)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setTracks (value) {
      this.tracks = value
    },
    handleTrackSubscribed (track) {
      this.setTracks([...this.tracks, track])
    },
    handleTrackUnsubscribed (track) {
      this.setTracks(this.tracks?.filter((t) => t !== track))
    },
    setTrackSubscribedOnParticipant (data) {
      if (!this.participantData) return

      const newParticipant = data || this.participantData
      newParticipant.on('trackSubscribed', this.handleTrackSubscribed)
      newParticipant.on('trackUnsubscribed', this.handleTrackUnsubscribed)
    },
    setTrackSubscribedOffParticipant (data) {
      if (!this.participantData) return

      const newParticipant = data || this.participantData
      newParticipant.off('trackSubscribed', this.handleTrackSubscribed)
      newParticipant.off('trackUnsubscribed', this.handleTrackUnsubscribed)
    }
  },
  destroyed () {
    this.setTrackSubscribedOffParticipant()
  }
})
