import Video from 'twilio-video'
import appUtils from '../../utils/appUtils'

window.TwilioVideo = Video

export const useRoom = (localTracks, connectionOptions) => ({
  data () {
    return {
      room: null,
      isConnecting: false,
      optionsRef: {},
      tracks: []
    }
  },
  watch: {
    localTracks: {
      handler (data) {
        this.tracks = data
      },
      deep: true
    },
    connectionOptions: {
      handler (data) {
        this.optionsRef = {
          ...data
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async connect (token, options) {
      this.isConnecting = true
      return Video.connect(token, {
        ...this.optionsRef,
        ...options,
        tracks: this.tracks
      })
        .then((newRoom) => {
          this.room = newRoom

          this.room.localParticipant.setNetworkQualityConfiguration({
            local: 2,
            remote: 1
          })

          const disconnect = () => this.room.disconnect()

          // This app can add up to 16 'participantDisconnected' listeners to the room object, which can trigger
          // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
          this.room.setMaxListeners(16)

          this.room.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => (this.room = null))
            window.removeEventListener('beforeunload', disconnect)

            if (appUtils.isMobile) {
              window.removeEventListener('pagehide', disconnect)
            }
          })

          // @ts-ignore
          window.twilioRoom = this.room

          this.room.localParticipant.videoTracks.forEach((publication) =>
            // All video tracks are published with 'low' priority because the video track
            // that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            publication.setPriority('low')
          )

          this.isConnecting = false

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect)

          if (appUtils.isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect)
          }
        })
        .catch((error) => {
          console.log(`ERROR: ${error.message}`, error)
          this.isConnecting = false
        })
    }
  }
})
