export const useTrack = (publication) => ({
  data () {
    return {
      publicationData: null,
      track: null
    }
  },
  watch: {
    publication: {
      handler (data) {
        this.publicationData = data
      },
      deep: true,
      immediate: true
    },
    publicationData: {
      handler (data) {
        this.setTrack(data && data?.track)

        if (data) {
          this.removeTrack()
          this.setSubscribedOnPublication(data)
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setTrack (value) {
      this.track = value
    },
    removeTrack () {
      this.track = null
    },
    setSubscribedOnPublication (data) {
      if (!this.publicationData) return

      const publication = data || this.publicationData
      this.track = publication.track
      publication.on('subscribed', this.setTrack)
      publication.on('unsubscribed', this.removeTrack)
    },
    setSubscribedOffPublication (data) {
      if (!this.publicationData) return

      const publication = data || this.publicationData
      this.track = publication.track
      publication.off('subscribed', this.setTrack)
      publication.off('unsubscribed', this.removeTrack)
    }
  },
  destroyed () {
    this.setSubscribedOffPublication()
  }
})
