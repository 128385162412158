export const useLocalDevicesToggle = {
  mixins: [],
  inject: ['videoProvide', 'getLocalVideoTrack', 'removeLocalVideoTrack'],
  data () {
    return {
      isPublishing: false,
      videoTrack: null,
      audioTrack: null,
      isEnabled: false,
      isVideoEnabled: false,
      localTracks: this.videoProvide.localTracks || [],
      localParticipant: this.videoProvide?.room?.localParticipant || null
    }
  },
  watch: {
    videoProvide: {
      handler (data) {
        this.localTracks = data.localTracks || []
        this.localParticipant = data?.room?.localParticipant || null
      },
      deep: true,
      immediate: true
    },
    localTracks: {
      handler (data) {
        this.setAudioAndVideoTracks(data)
      }
    },
    audioTrack: {
      handler (data) {
        this.getIsTrackEnabled(data)
      },
      deep: true
    },
    videoTrack: {
      handler (data) {
        this.isVideoEnabled = !!data
      },
      deep: true
    }
  },
  mounted () {
    this.setAudioAndVideoTracks()
  },
  methods: {
    setAudioAndVideoTracks (data) {
      const localTracksData = data || this.localTracks || []

      this.videoTrack = localTracksData.find(
        (track) => !track.name.includes('screen') && track.kind === 'video'
      )
      this.audioTrack = localTracksData.find((track) => track.kind === 'audio')
    },
    async toggleVideoEnabled () {
      if (!this.isPublishing) {
        if (this.videoTrack) {
          const localTrackPublication = this.localParticipant?.unpublishTrack(
            this.videoTrack
          )
          // eslint-disable-next-line no-unused-expressions
          this.localParticipant?.emit(
            'trackUnpublished',
            localTrackPublication
          )
          this.removeLocalVideoTrack()
          this.videoTrack = null
        } else {
          this.isPublishing = true
          await this.getLocalVideoTrack()
            .then((track) => {
              // eslint-disable-next-line no-unused-expressions
              this.localParticipant?.publishTrack(track, { priority: 'low' })
              // console.log(this.localParticipant, track)
              this.videoTrack = track
            })
            .catch((error) => console.log(error))
            .finally(() => {
              this.isPublishing = false
            })
        }
      }
    },
    toggleAudioEnabled () {
      if (this.audioTrack) {
        this.audioTrack.isEnabled
          ? this.audioTrack.disable()
          : this.audioTrack.enable()

        this.getIsTrackEnabled(this.audioTrack)
      }
    },
    getIsTrackEnabled (track) {
      this.isEnabled = track ? track.isEnabled : false

      if (track) {
        const setEnabled = () => (this.isEnabled = true)
        const setDisabled = () => (this.isEnabled = false)
        track.on('enabled', setEnabled)
        track.on('disabled', setDisabled)
      }
    },
    resetIsTrackEnabled (track) {
      if (track) {
        const setEnabled = () => (this.isEnabled = true)
        const setDisabled = () => (this.isEnabled = false)
        track.off('enabled', setEnabled)
        track.off('disabled', setDisabled)
      }
    }
  },
  destroyed () {
    this.resetIsTrackEnabled(this.audioTrack)
  }
}
