export const usePublications = (participant) => ({
  inject: ['videoProvide'],
  data () {
    return {
      participantData: null,
      publications: []
    }
  },
  watch: {
    participant: {
      handler (data) {
        this.participantData = data
      },
      deep: true,
      immediate: true
    },
    participantData: {
      handler (data) {
        this.publications = Array.from(data?.tracks?.values()) || []
        this.setParticipantOnTrackPublished(data)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    publicationAdded (publication) {
      this.publications = [...this.publications, publication]
    },
    publicationRemoved (publication) {
      this.publications = this.publications.filter((p) => p !== publication)
    },
    setParticipantOnTrackPublished (data) {
      if (!this.participantData) return

      const participant = data || this.participantData
      participant.on('trackPublished', this.publicationAdded)
      participant.on('trackUnpublished', this.publicationRemoved)
    },
    setParticipantOffTrackPublished (data) {
      if (!this.participantData) return

      const participant = data || this.participantData
      participant.off('trackPublished', this.publicationAdded)
      participant.off('trackUnpublished', this.publicationRemoved)
    }
  },
  destroyed () {
    this.setParticipantOffTrackPublished()
  }
})
