import CreateRoom from '../views/rooms/CreateRoom.vue'
import Home from '../views/Home.vue'
import Login from '../views/login/Login.vue'
import MagicLinkJoin from '../views/rooms/MagicLinkJoin.vue'
import MdtRoom from '../views/rooms/MdtRoom.vue'
import MeetRoom from '../views/rooms/MeetRoom.vue'
import MeetRoomX from '../views/rooms/MeetRoomX.vue'
import PublicMdtRoom from '../views/rooms/PublicMdtRoom.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import WebAppJoin from '../views/rooms/WebAppJoin.vue'
import WebAppJoinMdt from '../views/rooms/WebAppJoinMdt.vue'
import WebAppJoinPMdt from '../views/rooms/WebAppJoinPMdt.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { layout: 'app' }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { layout: 'app' }
  },
  {
    path: '/create-room',
    name: 'CreateRoom',
    component: CreateRoom,
    meta: { layout: 'app' }
  },
  {
    path: '/meet/:name',
    name: 'MeetRoom',
    component: MeetRoom,
    meta: { layout: 'call' }
  },
  {
    path: '/meetx/:name',
    name: 'MeetRoomX',
    component: MeetRoomX,
    meta: { layout: 'video' }
  },
  {
    path: '/_meet/:name',
    name: 'MagicLinkJoin',
    component: MagicLinkJoin,
    meta: { layout: 'app' }
  },
  {
    path: '/_app/:name',
    name: 'WebAppJoin',
    component: WebAppJoin,
    meta: { layout: 'app' }
  },
  {
    path: '/_mdt/:name',
    name: 'WebAppJoinMdt',
    component: WebAppJoinMdt,
    meta: { layout: 'app' }
  },
  {
    path: '/mdt/:name',
    name: 'MdtRoom',
    component: MdtRoom,
    meta: { layout: 'call' }
  },
  {
    path: '/pmdt/:name',
    name: 'PublicMdtRoom',
    component: PublicMdtRoom,
    meta: { layout: 'call' }
  },
  {
    path: '/_pmdt/:name',
    name: 'WebAppJoinMdt',
    component: WebAppJoinPMdt,
    meta: { layout: 'app' }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
