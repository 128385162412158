export const useParticipants = {
  inject: ['videoProvide'],
  data () {
    return {
      room: null,
      participants: null
    }
  },
  watch: {
    videoProvide: {
      handler (data) {
        this.room = data.room || null
      },
      deep: true,
      immediate: true
    },
    room: {
      handler (data) {
        this.participants = Array.from(data?.participants?.values() || [])
        this.setRoomOnParticipant(data)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    participantConnected (participant) {
      this.participants = [...this.participants, participant]
    },
    participantDisconnected (participant) {
      this.participants = this.participants.filter((p) => p !== participant)
    },
    setRoomOnParticipant (data) {
      if (!this.room) return

      const roomData = data || this.room
      roomData.on('participantConnected', this.participantConnected)
      roomData.on('participantDisconnected', this.participantDisconnected)
    },
    setRoomOffParticipant (data) {
      if (!this.room) return

      const roomData = data || this.room
      roomData.off('participantConnected', this.participantConnected)
      roomData.off('participantDisconnected', this.participantDisconnected)
    }
  },
  destroyed () {
    this.setRoomOffParticipant()
  }
}
