/* eslint-disable */
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY,
} from "../../utils/constants";

import Video from "twilio-video";
import appUtils from "../../utils/appUtils";

const noiseCancellationOptions = {
  sdkAssetsPath: "/noisecancellation",
  vendor: "krisp",
};

export const useLocalTracks = {
  data() {
    return {
      audioTrack: null,
      videoTrack: null,
      isAcquiringLocalTracks: false,
      isKrispEnabled: false,
      isKrispInstalled: false,
    };
  },
  mouted() {},
  watch: {
    audioTrack: {
      handler(data) {
        // console.log(data);
      },
      depp: true,
    },
    videoTrack: {
      handler(data) {
        // console.log(data);
      },
      depp: true,
    },
  },
  computed: {
    localTracks() {
      return [this.audioTrack, this.videoTrack].filter(
        (track) => typeof track !== "undefined" && track !== null
      );
    },
  },
  methods: {
    setNoiseCancellation(enable) {
      const { noiseCancellation } = this.audioTrack;
      if (noiseCancellation) {
        if (enable) {
          // If enabled, then the LocalAudioTrack will use the Krisp noise
          // cancellation instead of the browser's noise suppression.
          noiseCancellation.enable();
          this.isKrispEnabled = true;
          this.isKrispInstalled = true;
        } else {
          // If disabled, then the LocalAudioTrack will use the browser's
          // noise suppression instead of the Krisp noise cancellation.
          noiseCancellation.disable();
          this.isKrispEnabled = false;
          this.isKrispInstalled = false;
        }
      }
    },
    async getLocalVideoTrack() {
      const selectedVideoDeviceId = window.localStorage.getItem(
        SELECTED_VIDEO_INPUT_KEY
      );

      const { videoInputDevices } = await appUtils.getDeviceInfo();

      const hasSelectedVideoDevice = videoInputDevices.some(
        (device) =>
          selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
      );

      const options = {
        ...DEFAULT_VIDEO_CONSTRAINTS,
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice &&
          selectedVideoDeviceId && {
            deviceId: { exact: selectedVideoDeviceId },
          }),
      };

      return await Video.createLocalVideoTrack(options).then((newTrack) => {
        this.videoTrack = newTrack;
        return newTrack;
      });
    },
    removeLocalAudioTrack() {
      if (this.audioTrack) {
        this.audioTrack.stop();
        this.audioTrack = undefined;
      }
    },
    removeLocalVideoTrack() {
      if (this.videoTrack) {
        this.videoTrack.stop();
        this.videoTrack = undefined;
      }
    },
    async getAudioAndVideoTracks() {
      const {
        audioInputDevices,
        videoInputDevices,
        hasAudioInputDevices,
        hasVideoInputDevices,
      } = await appUtils.getDeviceInfo();
      if (!hasAudioInputDevices && !hasVideoInputDevices)
        return Promise.resolve();
      if (this.isAcquiringLocalTracks || this.audioTrack || this.videoTrack)
        return Promise.resolve();
      this.isAcquiringLocalTracks = true;
      const selectedAudioDeviceId = window.localStorage.getItem(
        SELECTED_AUDIO_INPUT_KEY
      );
      const selectedVideoDeviceId = window.localStorage.getItem(
        SELECTED_VIDEO_INPUT_KEY
      );
      const hasSelectedAudioDevice = audioInputDevices.some(
        (device) =>
          selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
      );
      const hasSelectedVideoDevice = videoInputDevices.some(
        (device) =>
          selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
      );
      // In Chrome, it is possible to deny permissions to only audio or only video.
      // If that has happened, then we don't want to attempt to acquire the device.
      const isCameraPermissionDenied = await appUtils.isPermissionDenied(
        "camera"
      );
      const isMicrophonePermissionDenied = await appUtils.isPermissionDenied(
        "microphone"
      );
      const shouldAcquireVideo =
        hasVideoInputDevices && !isCameraPermissionDenied;
      // const shouldAcquireAudio =
      //   hasAudioInputDevices && !isMicrophonePermissionDenied;
      const shouldAcquireAudio = !isMicrophonePermissionDenied;
      const localTrackConstraints = {
        video: shouldAcquireVideo && {
          ...DEFAULT_VIDEO_CONSTRAINTS,
          name: `camera-${Date.now()}`,
          ...(hasSelectedVideoDevice &&
            selectedVideoDeviceId && {
              deviceId: { exact: selectedVideoDeviceId },
            }),
        },
        // audio: shouldAcquireAudio && {
        //   noiseCancellationOptions,
        //   ...(hasSelectedAudioDevice &&
        //     selectedAudioDeviceId && {
        //       deviceId: { exact: selectedAudioDeviceId },
        //     }),
        // },
        audio: shouldAcquireAudio && {
          noiseCancellationOptions,
          name: "microphone",
        },
      };
      return Video.createLocalTracks(localTrackConstraints)
        .then((tracks) => {
          console.log(tracks);
          const newVideoTrack = tracks.find((track) => track.kind === "video");
          const newAudioTrack = tracks.find((track) => track.kind === "audio");

          if (newVideoTrack) {
            this.videoTrack = newVideoTrack;
            // Save the deviceId so it can be picked up by the VideoInputList component. This only matters
            // in cases where the user's video is disabled.
            window.localStorage.setItem(
              SELECTED_VIDEO_INPUT_KEY,
              newVideoTrack.mediaStreamTrack.getSettings().deviceId || ""
            );
          }
          if (newAudioTrack) {
            this.audioTrack = newAudioTrack;
            if (newAudioTrack.noiseCancellation) {
              this.setNoiseCancellation(true);
            }
          }
          // These custom errors will be picked up by the MediaErrorSnackbar component.
          if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
            const error = new Error();
            error.name = "NotAllowedError";
            throw error;
          }
          if (isCameraPermissionDenied) {
            throw new Error("CameraPermissionsDenied");
          }
          if (isMicrophonePermissionDenied) {
            throw new Error("MicrophonePermissionsDenied");
          }
        })
        .finally(() => (this.isAcquiringLocalTracks = false));
    },
  },
};
